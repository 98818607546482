<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            Fee Offer Report
                            <v-spacer></v-spacer>
                        </v-card-title>

                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs4 sm4>
                                <v-select :disabled="gradeLoading" :loading="gradeLoading" :items="grades" class="pa-0"
                                          label="Grade" v-model="grade" outlined dense/>
                            </v-flex>
                            <v-flex xs2>
                                <search-button :disabled="!grade" style="" permission="receipt-read"
                                               @action="get()">
                                    Search
                                </search-button>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <print-buttons v-if="form.items.data.length > 0" :passAuthToReport="true"
                                           :downloadUrl="downloadUrl" :pdf="true"
                                           :excel="true" labelExcel="Download Excel" labelPdf="Download Pdf"></print-buttons>
                        </v-card-title>
                    </v-card>
                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :search="search"
                                  hide-default-footer
                                  footer-props.items-per-page-options="rowsPerPageItems"
                                  :options.sync="pagination" :server-items-length="form.items.meta.total"
                                  :loading="form.loading" class="dTable">
                        <template  v-slot:item="{index, item}">
                            <tr style="border: 1px solid black; border-right: 1px solid black">
                                <td class="text-xs-left" :rowspan="item.fee_offers.length + 1">
                                    {{item.name}}({{item.roll}}) {{item.fee_offers.length}}
                                    <p style="color: #bbb">{{(item.enroll_code)}}</p>
                                </td>
                                <td class="text-xs-left" :rowspan="item.fee_offers.length +1"
                                    style="border-right: 1px solid black">
                                    {{item.grade}} <sup>"{{item.section}}"</sup>
                                </td>
                            </tr>
                            <template v-for="(feeOffer,i) in item.fee_offers">
                                <tr :key="(i+100)*(index+1)" :class="{child:item.fee_offers.length===i+1}">
                                    <td class="text-xs-left">{{feeOffer.fee_head}}</td>
                                    <td class="text-xs-left">{{feeOffer.fee_amount}}</td>
                                    <td class="text-xs-left">{{feeOffer.discount_amount}}
                                        ({{feeOffer.discount_percentage}}%)
                                    </td>
                                    <td class="text-xs-left">{{feeOffer.scholar_amount}}
                                        ({{feeOffer.scholar_percentage}}%)
                                    </td>

                                </tr>
                            </template>

                        </template>
                        <template v-slot:body.append v-if="form.items.data.length">
                            <tr>
                                <td :colspan="3" class="text-xs-center"><strong>Total</strong></td>
                                <td><strong>{{feeHeadAmountSum.numberFormat()}}</strong></td>
                                <td><strong>{{feeHeadDiscountSum.numberFormat()}}</strong></td>
                                <td><strong>{{feeScholarshipSum.numberFormat()}}</strong></td>
                            </tr>
                        </template>
<!--                        <template slot="footer" v-if="form.items.data.length">-->
<!--                            <tr>-->
<!--                                <td :colspan="3" class="text-xs-center"><strong>Total</strong></td>-->
<!--                                <td><strong>{{feeHeadAmountSum.numberFormat()}}</strong></td>-->
<!--                                <td><strong>{{feeHeadDiscountSum.numberFormat()}}</strong></td>-->
<!--                                <td><strong>{{feeScholarshipSum.numberFormat()}}</strong></td>-->
<!--                            </tr>-->
<!--                        </template>-->
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            gradeLoading: false,
            form: new Form({
                bill_month: '',
                grade_id: '',
                enroll: '',

            }, '/api/report/fee-offer'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 1000
            },
            downloadUrl: '',
            headers: [
                // {text: 'Roll No.', align: 'left', value: 'roll_no', sortable: false},
                // {text: 'Enroll Code', align: 'left', value: 'enroll_code', sortable: false},
                {text: 'Name(Roll)', align: 'left', value: 'Name', sortable: false},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false},
                {text: 'Fee Head', align: 'left', value: 'title', sortable: false},
                {text: 'Fee Head Amount', align: 'left', value: 'amount', sortable: false},
                {text: 'Discount', align: 'left', value: 'discount', sortable: false},
                {text: 'Scholar', align: 'left', value: 'scholar', sortable: false},

            ],
            grades: [],
            grade: '',
            sections: [],
            section: '',
            collection: [],

            feeHeadAmountSum: 0,
            feeHeadDiscountSum: 0,
            feeScholarshipSum: 0
        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            this.getGrades();
        },

        watch: {
            'batch': function (value) {
                this.get();
            },
            'pagination': function () {
                this.get();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.grade;
            },

            get(params) {
                if (this.grade) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;

                    let $this = this;
                    this.form.get(null, query).then(({data}) => {
                        this.downloadUrl = data.download_url;

                        this.feeHeadAmountSum = 0;
                        this.feeHeadDiscountSum = 0;
                        this.feeScholarshipSum = 0;
                        //

                        data.data.map(function (item) {
                            item.fee_offers.map(function (feeOffer) {
                                $this.feeHeadAmountSum += parseFloat(feeOffer.fee_amount);
                                $this.feeHeadDiscountSum += parseFloat(feeOffer.discount_amount);
                                $this.feeScholarshipSum += parseFloat(feeOffer.scholar_amount);
                            });
                        });
                        this.pagination.totalItems = data.meta.total
                    })
                }
            },

            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false').then(({data}) => {
                    this.grades = [{value: 'all', text: 'ALL'}];
                    let $this = this;

                    data.data.map(item => {
                        $this.grades.push({value: item.id, text: item.name});
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },

            getSection() {
                if (this.grade) {
                    this.$rest.get('/api/section?grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    })
                }
            },

        }
    }
</script>
<style lang="scss">
    .child {
        border-bottom: 1px solid #666 !important;
    }

    .dTable table.v-datatable.v-table.theme--light {
        width: 99%;
        border: 1px solid black;
        align: centre;
    }

</style>